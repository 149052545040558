import request from '@/utils/request'

// 列表数量
export function getActivityListCount(params) {
  return request({
    url: '/api/activity/list/count',
    method: 'get',
    params
  })
}

// 列表
export function getActivityList(params) {
  return request({
    url: '/api/activity/list',
    method: 'get',
    params
  })
}


// 详情
export function getActivityDetail(params) {
  return request({
    url: '/api/activity/detail',
    method: 'get',
    params
  })
}

// 新建
export function createActivity(data) {
  return request({
    url: '/api/activity/create',
    method: 'post',
    data
  })
}

// 修改
export function updateActivity(data) {
  return request({
    url: '/api/activity/update',
    method: 'post',
    data
  })
}

