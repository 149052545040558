<template>
  <div class="tag-container">
    <div class="operator-bar">
      <el-button type="primary" icon="el-icon-edit" @click="handleCreate">新建活动</el-button>
    </div>

    <el-table :data="listData" max-height="570" v-loading="loading == 'list'">
      <el-table-column type="index" :index="indexMethod" label="序号" width="80" prop="no">
      </el-table-column>

      <el-table-column class-name="td-align-left" prop="activityName" label="活动名称" min-width="200" show-overflow-tooltip>
      </el-table-column>

      <el-table-column prop="thumb" label="封面图" width="150">
        <template  slot-scope="{ row }">
          <img :src="row.thumb" height="50">
        </template>
      </el-table-column>

      <el-table-column prop="activityStatus" label="状态" width="80">
        <template slot-scope="{ row }">
          <el-tag :type="row.activityStatus | statusFilter">
            {{ row.activityStatus | formatStata }}
          </el-tag>
        </template>
      </el-table-column>

       <el-table-column prop="address" label="地点" width="200" show-overflow-tooltip>
      </el-table-column>

      <el-table-column  label="活动时间" width="200">
        <template slot-scope="{ row }" v-if="row.activityStartTime && row.activityEndTime">
          {{ row.activityStartTime | formatTime('{y}-{m}-{d}') }} - {{ row.activityEndTime | formatTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>

      <el-table-column prop="applyEndTime" label="报名截止时间" width="150">
        <template slot-scope="{ row }" v-if="row.applyEndTime">
          {{ row.applyEndTime | formatTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      
      <el-table-column label="操作" width="150">
        <template slot-scope="{ row }">
          <el-button type="primary" @click="goDetail(row.activityId)" size="mini">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getActivityListCount, getActivityList} from '@/api/activity'
import { parseTime } from '@/utils/tool.js'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      columnId: this.$route.meta.columnId,
      listData:[],
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      },
      loading: false
    }
  },
  created() {
    this.getListCount()
    this.getList()
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    getListCount() {
      const params = {
        columnId: this.columnId
      }
      getActivityListCount(params).then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      const params = {
        columnId: this.columnId,
        page: this.listPage.page,
        pageSize: this.listPage.pageSize
      }
      this.loading = 'list'
      getActivityList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    goDetail(activityId) {
      this.$router.push(this.$route.path+'/detail?activityId='+activityId)
    },
    handleCreate() {
      this.$router.push(this.$route.path+'/edit')
    }
  },
  filters: {
    formatTime(time, format) {
      return parseTime(time, format)
    },
    statusFilter(status) {
      const statusMap = {
        'OPEN': 'success',
        'HIDDEN': 'info'
      }
      return statusMap[status]
    },
    formatStata(status) {
      const statusMap = {
        'OPEN': '已开启',
        'HIDDEN': '已隐藏'
      }
      return statusMap[status]
    }
  }
}
</script>