var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-container"},[_c('div',{staticClass:"operator-bar"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit"},on:{"click":_vm.handleCreate}},[_vm._v("新建活动")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading == 'list'),expression:"loading == 'list'"}],attrs:{"data":_vm.listData,"max-height":"570"}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.indexMethod,"label":"序号","width":"80","prop":"no"}}),_c('el-table-column',{attrs:{"class-name":"td-align-left","prop":"activityName","label":"活动名称","min-width":"200","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"thumb","label":"封面图","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.thumb,"height":"50"}})]}}])}),_c('el-table-column',{attrs:{"prop":"activityStatus","label":"状态","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.activityStatus)}},[_vm._v(" "+_vm._s(_vm._f("formatStata")(row.activityStatus))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"地点","width":"200","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"活动时间","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.activityStartTime && row.activityEndTime)?[_vm._v(" "+_vm._s(_vm._f("formatTime")(row.activityStartTime,'{y}-{m}-{d}'))+" - "+_vm._s(_vm._f("formatTime")(row.activityEndTime,'{y}-{m}-{d}'))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"applyEndTime","label":"报名截止时间","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.applyEndTime)?[_vm._v(" "+_vm._s(_vm._f("formatTime")(row.applyEndTime,'{y}-{m}-{d}'))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.goDetail(row.activityId)}}},[_vm._v(" 详情 ")])]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.listPage.total>0),expression:"listPage.total>0"}],attrs:{"total":_vm.listPage.total,"page":_vm.listPage.page,"limit":_vm.listPage.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.listPage, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listPage, "pageSize", $event)},"pagination":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }